<template>
  <div class="fullscreen d-flex justify-center align-center">
    <LoginForm></LoginForm>
  </div>
</template>

<script>
import LoginForm from "@/components/loggable/LoginForm.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>

<style scoped>
.fullscreen {
  height: 100vh;
  width: 100vw;
}
</style>
