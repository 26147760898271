<template>
  <div class="flex-column flex-wrap d-flex justify-center align-center">
    <v-img
      max-height="150"
      max-width="250" 
      src="@/assets/LOGO_LULUU.png"
    ></v-img>
    <h3 class="mb-5">Log In</h3>
    <LoginForm
      color="primary"
      username-label="Email"
      login-button-alignment="center"
      login-button-block
      :loading="loading"
      clearable
      solo
      style="max-width: 60vw; width: 400px"
      :password-error="loginFailed ? 'Credenziali errate' : undefined"
      @login-click="handleLogin"
      @change="handleChange"
    ></LoginForm>
    <div class="mt-8">
      Hai dimenticato la password? <a @click="handleResetPasswordClick">Resettala qua</a>
    </div>
    <div class="mt-8 flex flex-wrap gap">
      Non hai un account? <a @click="handleRegistrationClick">Registrati per il periodo di prova</a>
    </div>
    <SimplePopUp
      v-model="expiredLicense"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '20vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      alert-text="La tua licenza è scaduta, contatta l'assistenza per rinnovarla"
      color="warning"
      :backgroundImage="undefined"
      icon="mdi-alert-octagon"
    >
      <template v-slot:footer>
        <div
          style="margin-top: 18px; margin-bottom: -12px; 
            display: flex; justify-content: center;
            align-items: center;"
        >
          <v-btn 
            color="error"
            @click="openCustomerSupport"
          >
            Contatta l'assistenza
          </v-btn>
        </div>
      </template>
    </SimplePopUp>
  </div>
</template>

<script>

import { LoginForm } from "likablehair-ui-components";
import authService from "@/services/auth/auth.service.js";
import SimplePopUp from '@/components/common/SimplePopUp.vue'
//import luluu from "@/src/components/loggable/LOGO_LULUU.png"

export default {
  name: "Login",
  components: {
    LoginForm,
    SimplePopUp
  },
  data: function () {
    return {
      loginFailed: false,
      loading: false,
      resolvePath: '/dashboard',
      email: '',
      expiredLicense: false
    };
  },
  mounted: function() {
    if(this.$route.params.resolvePath) {
      this.resolvePath = this.$route.params.resolvePath
    }
  },
  methods: {
    handleLogin(email, password) {
      this.loading = true
      authService
        .login({ email: email, password: password })
        .then((response) => {
          this.$router.push({ path: this.resolvePath }).then(_ => {
            this.loading = false
            let today = new Date()
            let firstOfJanuary = new Date(today.getFullYear(), 0, 1)
            let fifthOfJanuary = new Date(today.getFullYear(), 0, 5)

            if(today < fifthOfJanuary && today > firstOfJanuary) {
              setTimeout(() => {
                this.$delegates.snackbar('Attenzione! Un nuovo anno è iniziato, se non lo hai ancora fatto, resetta contatore del documento commerciale della stampante RT!!!', 10000)
              }, 1000);
            }
          });
        }).catch((error) => {
          this.loginFailed = true
          this.loading = false
          if(error.message == "license expired")
            this.expiredLicense = true
        })
    },
    handleChange(params) {
      this.email = params.username

      this.loginFailed = false
    },
    handleResetPasswordClick() {
      if(!!this.email)
        authService.forgotPassword({ email: this.email })
          .then(() => {
            this.$delegates.snackbar('Messaggio inviato', 5000)
          })
          .catch(() => {
            this.$delegates.snackbar('Errore durante l\'invio del messaggio', 5000)
          })
      else 
        this.$delegates.snackbar('Inserisci la tua email per resettare la password', 5000)
    },
    handleRegistrationClick() {
      this.$router.push({ name: 'Registration' })
    },
    openCustomerSupport() {
      let clickUpLink = 'https://forms.clickup.com/f/hffyw-4428/1M1PSFG5AOV8DUQCUG'
      window.open(clickUpLink, '_blank').focus()
    },
  },
};
</script>

<style scoped>
.fullscreen {
  height: 100vh;
  width: 100vw;
}

h2 {
  font-size: 2em;
  letter-spacing: -3px;
  font-weight: 700;
  color: black;
}

h3 {
  font-size: 1.2em;
  letter-spacing: -3px;
  font-weight: 700;
  color: black;
}

.gap {
  gap: 10px;
  padding: 0 20px;
}
</style>
